
export default {
  version: "1.0.0",
  tips: {
    errCode: {
      400: "参数错误",
      403: "权限受限，请联系管理员",
      404: "请求链接不存在，请联系管理员",
      405: "请求方式不正确，应为POST/GET",
      500: "服务器错误，请联系管理员",
      default: "无效的请求，状态码（$）,请联系管理员",
      other: "未知错误，请联系管理排查"
    }
  },
  globalProject: 1, // 公众号openid
  // branch:"dev",
  // localhost:"/kapi",
  localhost: "http://yth.bestseed.medflying.com",
  host: "http://yth.bestseed.medflying.com",
  live: "ws://yth.bestseed.medflying.com/chat/live", // 正式服记得改
  chat: "ws://yth.bestseed.medflying.com/chat/client", // 正式服记得改
  prefix: "",
  urlPrefix: "/ui",
  title: "BestSeed",
  types: {
    exports: {
      zip: 'application/zip',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    },
    roles: {
      zx: "ROLE_ZHUXI",
      tl: "ROLE_TAOLUN",
      jz: "ROLE_JIANGZHE",
    },
    roleMap: {
      ROLE_ZHUXI: "会议主席",
      ROLE_TAOLUN: "讨论嘉宾",
      ROLE_JIANGZHE: "会议讲者",
    },
    meetStatus: { 0: "即将开始", 10: "直播中", 20: "已结束" },
    status: [{ label: "否", value: 0 }, { label: "是", value: 1 }],
    exports: {
      zip: 'application/zip',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    },
  },
  wxinterface: [
    "updateAppMessageShareData",
    "onMenuShareAppMessage",
    "onMenuShareTimeline",
    "updateTimelineShareData",
  ],
  role: [{ label: '会议主席', value: 1, text: '主席位空缺' }, { label: '会议讲者', value: 2 }, { label: '讨论嘉宾', value: 3, text: '讨论位空缺' }],
}